import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import ProductItem from '../../../../shop-ui/components/product-item/ProductItem';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_FACTOR } from '../../../../shop-ui/constants';
import { ISliderItemProps } from '../../../interfaces';

const DefaultSliderItemComponent: React.FunctionComponent<ISliderItemProps> = props => {
    const { item, storeConfig, useLazyLoad, isVisible, hideNotVisibleItems } = props;
    const seoTitleSecondary = item.seoTitleSecondary ?? null;
    const sku = item.abstractSku;
    const name = item.abstractName;
    const brandName = item.brandName;
    const attributes = item.attributes;
    const url = item.url;
    const images = item.images;
    const ratingAverage = attributes.ratingAverage ?? 0;
    const customerPrice = item.prices.customer ?? null;
    const catalogPrice = item.prices.catalog ?? null;
    const merchantPrice = item.prices.merchant ?? null;
    const isNew = attributes.isNew ?? false;
    const hasMultiplePrices = item.hasMultiplePrices ?? false;
    const basicPrice = item.basicPrice ?? null;
    const isSale = attributes.isSale ?? false;
    const currency = storeConfig?.currency ?? DEFAULT_CURRENCY;
    const currencyFactor = storeConfig?.currencyFactor ?? DEFAULT_CURRENCY_FACTOR;
    const type = item.type;
    const catalogOrigin = attributes.catalogOrigin;
    const gtm = item.extraInformation && item.extraInformation.gtm ? item.extraInformation.gtm : null;
    const clickTrackingParams = item.extraInformation && item.extraInformation.clickTrackingParams ? item.extraInformation.clickTrackingParams : null;

    return (
        <ProductItem
            sku={sku}
            name={name}
            brandName={brandName}
            url={url}
            images={images}
            isNew={isNew}
            isSale={isSale}
            ratingAverage={ratingAverage}
            hasMultiplePrices={hasMultiplePrices}
            catalogPrice={catalogPrice}
            customerPrice={customerPrice}
            merchantPrice={merchantPrice}
            basicPrice={basicPrice}
            currency={currency}
            currencyFactor={currencyFactor}
            type={type}
            catalogOrigin={catalogOrigin}
            useLazyLoad={useLazyLoad}
            gtm={gtm}
            clickTrackingParams={clickTrackingParams}
            isKeyboardAccessible={hideNotVisibleItems && !isVisible ? false : true}
            isInViewport={isVisible}
            isWishlistAccessible={false}
            seoTitleSecondary={seoTitleSecondary}
        />
    );
}

const DefaultSliderItem = withMemo(DefaultSliderItemComponent);

export default DefaultSliderItem;
