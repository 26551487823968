import i18next from 'i18next';

class ReadMore {
    private static DEFAULT = {
        READ_MORE_TEXT: '.read-more__text',
        READ_MORE_BUTTON: '.read-more__button',
        READ_MORE_TEXT_GRADIENT: '.read-more__text__gradient',
        READ_MORE_TEXT_CLOSED: 'read-more__text--closed',
        DATA_READ_MORE_OPEN: 'data-read-more-open',
        READ_MORE_MAX_LINE: 'readMoreMaxLine',
    };

    private static TEXT = {
        SHOW_MORE: 'Show more',
        SHOW_LESS: 'Show less',
    };

    private readMoreContainer: HTMLElement;
    private readMoreText: HTMLElement;
    private readMoreButton: HTMLElement;
    private readMoreTextGradient: HTMLElement;
    private closedHeight: number;

    constructor(readMoreElement: HTMLElement) {
        this.readMoreContainer = readMoreElement;
        this.readMoreText = this.readMoreContainer.querySelector(ReadMore.DEFAULT.READ_MORE_TEXT);
        this.readMoreButton = this.readMoreContainer.querySelector(ReadMore.DEFAULT.READ_MORE_BUTTON);
        this.readMoreTextGradient = this.readMoreContainer.querySelector(ReadMore.DEFAULT.READ_MORE_TEXT_GRADIENT);

        this.init();
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.readMoreButton.addEventListener('click', _ => {
            if ('false' === this.readMoreButton.getAttribute(ReadMore.DEFAULT.DATA_READ_MORE_OPEN)) {
                this.readMoreText.classList.remove(ReadMore.DEFAULT.READ_MORE_TEXT_CLOSED);
                this.readMoreText.style.maxHeight = '';
                this.readMoreButton.innerText = i18next.t(ReadMore.TEXT.SHOW_LESS, { ns: 'layout' });
                this.readMoreButton.setAttribute(ReadMore.DEFAULT.DATA_READ_MORE_OPEN, 'true');
                this.readMoreTextGradient.style.display = 'none';
            } else {
                this.readMoreText.classList.add(ReadMore.DEFAULT.READ_MORE_TEXT_CLOSED);
                this.readMoreText.style.maxHeight = this.closedHeight + 'px';
                this.readMoreButton.innerText = i18next.t(ReadMore.TEXT.SHOW_MORE, { ns: 'layout' });
                this.readMoreButton.setAttribute(ReadMore.DEFAULT.DATA_READ_MORE_OPEN, 'false');
                this.readMoreTextGradient.style.display = 'block';
            }
        });
    }

    private init = (): void => {
        const maxLine = parseInt(this.readMoreContainer.dataset[ReadMore.DEFAULT.READ_MORE_MAX_LINE], 10);
        const paragraph = this.readMoreText.querySelector('p');

        if (paragraph) {
            const paragraphStyle = window.getComputedStyle(paragraph);
            const lineHeight = parseInt(paragraphStyle.getPropertyValue('line-height'), 10);
            const pMarginTop = parseInt(paragraphStyle.getPropertyValue('margin-top'), 10);
            const pMarginBottom = parseInt(paragraphStyle.getPropertyValue('margin-bottom'), 10);

            this.closedHeight = (maxLine * lineHeight) + (pMarginTop + pMarginBottom) - maxLine;
            this.readMoreText.classList.add(ReadMore.DEFAULT.READ_MORE_TEXT_CLOSED);
            this.readMoreTextGradient.style.opacity = '1';
            this.readMoreText.style.maxHeight = this.closedHeight + 'px';
            this.readMoreButton.innerText = i18next.t(ReadMore.TEXT.SHOW_MORE, {ns:'layout'});

            this.readMoreContainer.setAttribute('data-initialized', 'true');
        }
    }
}

export default class ReadMoreHandler {
    public static init = (): void => {
        document.querySelectorAll('.read-more').forEach((readMoreElement: HTMLElement): void => {
            const isReadMoreInitialized = readMoreElement.getAttribute('data-initialized') === 'true';

            if (!isReadMoreInitialized) {
                new ReadMore(readMoreElement);
            }
        });
    }
}
