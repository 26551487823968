import * as React from 'react';
import ('PyzShopUi/components/molecules/helmet-buyer-guide-landing/helmet-buyer-guide-landing.scss');

interface HelmetBuyerGuideTeaserProps {
    additonalClassnames?: string;
}

export const HelmetBuyerGuideTeaser: React.FunctionComponent<React.PropsWithChildren<HelmetBuyerGuideTeaserProps>> = props => {
    const { additonalClassnames, children } = props;

    return (
        <div className={`hbg-teaser ${additonalClassnames}`}>
            <div className="hbg-teaser__info">
                <div className="hbg-teaser__info__container">
                    {children}
                </div>
            </div>
        </div>
    );
};

HelmetBuyerGuideTeaser.defaultProps = {
    additonalClassnames: '',
}
